@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: var(--spacing-core-4);
    background-color: var(--color-background-quinary-default);
    margin-top: var(--spacing-core-8);
    padding: var(--spacing-core-6);
    border-radius: var(--spacing-core-4);
    color: var(--color-foreground-inverted-default);

    @include mq.mq($from: extra-large) {
        gap: var(--spacing-core-5);
        padding: var(--spacing-core-6);
    }
}

.text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rating {
    display: flex;
    flex-direction: row-reverse;
    gap: var(--spacing-core-2);

    @include mq.mq($from: medium) {
        gap: var(--spacing-core-1);      
    }
}

.icon {
    outline: none;
}

.star {
    width: var(--size-core-10);
    padding: 0;
    background: inherit;
    border: none;
    cursor: pointer;
    color: var(--color-background-quaternary-default);

    @include mq.mq($from: extra-large) {
        padding-right: var(--spacing-core-1);        
    }
}

.opinion {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-core-2);

    button {
        font-family: HamburgTS, Arial, Helvetica, sans-serif;
    }
}

